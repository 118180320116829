const hostPath = 'https://cosmic-energy.test.keldysh.io';

export const environment = {
  hostPath: hostPath,
  apiPath: hostPath + '/api/platform-service',
  firebaseConfig: {
    apiKey: "AIzaSyB9xn558hUmxJ11ureJD30gJXc8wMpGOpw",
    authDomain: "cosmic-energy-test.firebaseapp.com",
    projectId: "cosmic-energy-test",
    storageBucket: "cosmic-energy-test.appspot.com",
    messagingSenderId: "832960039301",
    appId: "1:832960039301:web:d5756eaaebbd1b50e69cec",
    measurementId: "G-22X28T49SN"
  },
  production: false
};
